<template>
  <div class="videoadd">
    <van-nav-bar
      title="添加视频"
      right-text="返回首页"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="title"
        name="title"
        placeholder="填写标题（不超过15个字符）"
        :rules="[{ required: true }]"
      >
      </van-field>
      <van-field
        label="教程类型"
        label-width="60"
        v-model="vtypestr"
        name="vtypestr"
        is-link
        readonly
        input-align="right"
        @click="showVtypeList(vtype)"
      >
      </van-field>
      <div class="check_video" v-show="vtype== 0 ? true : false">
        <div class="check_video_check" v-show="videosrc.length > 10 ? false : true">
          <van-tag style="margin-right: 10px;" color="#333" text-color="#fff" @click="showDouyin"><van-icon name="add-o" style="margin-right: 5px;" />添加抖音视频</van-tag>
          <!-- <van-uploader accept="video/*">
            <van-tag color="#333" text-color="#fff">
              <van-icon name="add-o" style="margin-right: 5px;" />添加本地视频
            </van-tag>
          </van-uploader> -->
        </div>
        <div class="check_video_shwo" v-show="videosrc.length > 10 ? true : false">
          <video
            id="video"
            height="200"
            width="100%"
            webkit-playsinline="true"
            x5-video-player-type="h5"
            x5-video-orientation="portraint"
            controls
            :poster="videoThumb"
            :src="videosrc"
          ></video>
        </div>
      </div>
      <van-field
        label="教程封面"
      >
        <template #button>
          <van-uploader v-model="fileList" :max-count="1" :max-size="50 * 1024" @oversize="onOversize" :after-read="uploadImg" />
        </template>
      </van-field>
      <van-field
        label="选择分类"
        label-width="60"
        v-model="groupstr"
        name="groupstr"
        is-link
        readonly
        input-align="right"
        @click="showGroupList(group)"
      >
      </van-field>
      <van-field
        label="是否出售"
        label-width="60"
        is-link
        readonly
        input-align="right"
      >
        <template #button>
          <van-radio-group v-model="isSalse" direction="horizontal">
            <van-radio :name="0" checked-color="#EE0A24" icon-size="16px">否</van-radio>
            <van-radio :name="1" checked-color="#EE0A24" icon-size="16px">是</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        label="价格"
        label-width="60"
        v-model="price"
        name="price"
        placeholder="填写价格"
        input-align="right"
        type="digit"
        v-show="isSalse == 1 ? true: false"
      >
        <template #right-icon>
          元
        </template>
      </van-field>
      <div class="wenan_title van-hairline--bottom">教程文案</div>
      <van-field
        v-model="content"
        rows="5"
        type="textarea"
        placeholder="教程文案"
      />
      <div style="margin: 50px 16px 16px;">
        <van-button round block type="danger" :loading="btnload" :native-type="!btnload ? 'submit' : 'button'">
          提交
        </van-button>
      </div>
    </van-form>
    <van-action-sheet v-model="vtypeShow" :actions="vtypearr" @select="onSelect" cancel-text="取消" close-on-click-action />
    <van-action-sheet v-model="showGroup" :actions="grouparr" @select="onSelectGroup" cancel-text="取消" close-on-click-action />

    <van-dialog v-model="showDouyinKuang" show-cancel-button @confirm="getvideo" @cancel="closeDouyinTan" confirm-button-text="获取视频">
      <template #title>
        <div class="add_douyin_title">
          添加抖音视频
          <span class="add_douyin_tishi" @click="yunlan">如何获取？</span>
        </div>
      </template>
      <template #default>
        <van-field
          name="shareinfo"
          v-model="shareinfo"
          ref="shareinfo"
          rows="4"
          type="textarea"
          show-word-limit
          :border="false"
          placeholder="粘贴在抖音复制的分享链接，获取分享内容"
        />
      </template>
    </van-dialog>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "VideoAdd",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      title: "", // 视频标题
      vtypearr: [{name:'视频',index: 0},{name:'图文',index: 1}],
      vtype: 0, // 教程类型
      vtypestr: '视频', // 教程类型文字
      vtypeShow: false,
      btnload: false,
      videosrc: "", // 视频地址
      videoThumb: "", // 视频封面地址
      shareinfo: "", // 抖音分享内容
      videoGetUrl: "", // 获取视频的url
      douyinshili: require("../../assets/douyin.gif"),
      fileList: [],
      grouparr: [{id:1,name: '新人必看'},{id:2,name: '进阶成长'},{id:3,name: '引流增粉'}],
      group: 0,
      groupstr: '选择',
      showGroup: false,
      isSalse: 0,
      price: 0,
      showDouyinKuang: false, // 是否显示添加抖音视频连接
      content: '',
    };
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid;
    } else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "添加视频");
    this.init();
  },
  methods: {
    init() {
      console.log("添加视频");
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 返回首页
    onClickRight() {
      this.$router.replace({
        name: "Home",
        query: { shareid: this.shareid, merchid: this.merchid, _t: 3 }
      });
    },
    // 保存上传视频
    onSubmit() {
      console.log("ssss");
      let _that = null;
      _that = this;
      if(!_that.btnload) {
        _that.btnload = true;
        _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/videos/video_two_add",
            _that.$qs.stringify({
              title: _that.title, // 视频标题
              videosrc: _that.videosrc, // 视频地址
              thumb: _that.videoThumb, // 视频封面地址
              videoGetUrl: _that.videoGetUrl, // 获取视频的url
              content: _that.content,
              vtype: _that.vtype,
              group: _that.group,
              isSalse: _that.isSalse,
              price: _that.price,
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.$toast(res.data.msg ? res.data.msg : "保存成功");
              _that.btnload = false;
              setTimeout(() => {
                _that.onClickLeft();
              }, 1000);
            }else {
              _that.$toast(res.data.msg ? res.data.msg : "保存失败");
              _that.btnload = false;
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 获取视频内容
    getvideo() {
      let _that = null;
      _that = this;
      console.log(_that.shareinfo);
      if (!_that.shareinfo) {
        _that.$refs.shareinfo.focus();
        return false;
      }
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/get_douyin",
          _that.$qs.stringify({
            shareurl: _that.shareinfo
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.content = res.data.data.content;
            _that.videosrc = res.data.data.videoSrc;
            _that.videoThumb = res.data.data.thumb;
            _that.fileList = [{url:res.data.data.thumb, isImage: true}];
            _that.videoGetUrl = res.data.data.url;
            _that.$toast(res.data.data.msg ? res.data.data.msg : "获取成功");
          }else {
            _that.$toast(res.data.data.msg ? res.data.data.msg : "获取失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    yunlan() {
      console.log(this.douyinshili);
      ImagePreview({
        images: [this.douyinshili],
        closeable: true,
        showIndex: false,
        className: "yulan",
        getContainer: ".videoadd"
      });
    },
    showVtypeList(index) {
      this.vtypeShow = true;
    },
    onSelect(item) {
      this.vtype = item.index;
      this.vtypestr = item.name;
      this.vtypeShow = false;
      console.log(item);
    },
    onOversize(file) {
      console.log(file);
      this.$toast('文件大小不能超过 50kb');
    },

    showGroupList() {
      this.showGroup = true;
    },
    onSelectGroup(item) {
      this.group = item.id;
      this.groupstr = item.name;
      this.showGroup = false;
    },

    showDouyin() {
      this.showDouyinKuang = true;
    },
    closeDouyinTan() {
      this.showDouyinKuang = false;
    },
    // 上传视频
    uploadImg(file) {
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/video/');
      fordata.append('merchid',_that.merchid);

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data"
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data.code == 100000) {
          _that.fileList = [{url: response.data.img_url, isImage: true}];
        }
      }).catch( error =>{
        console.log(error);
      })
    },
  }
};
</script>
<style lang="less">
.videoadd {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #fff;
  z-index: 2;
  .van-nav-bar .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #333;
  }
  .van-form {
    .van-cell__value {
      flex: 1;
    }
    .check_video {
      overflow: hidden;
      .check_video_check {
        margin: 16px;
        border: 1px dashed #333;
        border-radius: 10px;
        overflow: hidden;
        height: 200px;
        line-height: 200px;
      }
      .check_video_shwo {
        margin: 16px;
        height: 200px;
      }
    }
    .wenan_title {
      margin: 10px 16px 0;
      text-align: left;
      font-size: 14px;
      height: 44px;
      line-height: 44px;
    }
  }
  
  // 添加抖音视频弹框样式
  .add_douyin_title {
    width: 100%;
    text-align: center;
    position: relative;
    .add_douyin_tishi {
      position: absolute;
      right: 16px;
      top: 0;
      font-size: 10px;
      color: #999;
    }
  }
  .van-dialog {
    .van-cell__value {
      flex: 1;
    }
  }
}
</style>